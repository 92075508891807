import React from 'react'

import { Navbar, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.svg'

const classes = {
  menuLink: {
    marginLeft: '10px',
    textDecoration: 'none',
    color: '#f4f4f4',
    textTransform: 'uppercase'
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#f4f4f3',
    textTransform: 'uppercase'
  }
}

const NavMenu = () => {
  return (
    <Navbar bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand>
          <img
            alt='logo'
            src={Logo}
            width='30'
            height='30'
            className='d-inline-block align-top'
          />{' '}
          <Link style={classes.linkStyle} to='/'>
            {' '}
            SoyaStudios{' '}
          </Link>
        </Navbar.Brand>
        <Link style={classes.menuLink} to='/'>
          Home
        </Link>{' '}
        <Link style={classes.menuLink} to='/panel'>
          Panel
        </Link>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            Zalogowany jako:{' '}
            <Link style={classes.linkStyle} to='/login'>
              Mikołaj
            </Link>
          </Navbar.Text>{' '}
          <Link style={classes.menuLink} to='/login'>
            Login
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavMenu
