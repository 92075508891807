import React from 'react'
import SheetsUpdater from '../components/SheetsUpdater'

const Panel = () => {
  return (
    <div style={{ maxWidth: '360px', margin: '100px auto' }}>
      <SheetsUpdater />
    </div>
  )
}

export default Panel
