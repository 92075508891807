import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'
import NavMenu from './components/NavMenu'
import Panel from './pages/Panel'
import Home from './pages/Home'
import Login from './components/Login'

function App () {
  return (
    <div className='App'>
      <BrowserRouter>
        <NavMenu />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/panel'>
            <Panel />
          </Route>
          <Route path='/login'>
            <Login />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
